import React, { useState, useEffect } from "react";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import { backendUrl, callAxios } from "../../utils/axios";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  AutocompleteElement,
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { VisibilityOff, Visibility, Image } from "@mui/icons-material";

import { states } from "../../constants/states";

const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",

      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",

      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",

      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",

      fontWeight: 400,
    },
    "& .MuiInputBase-input": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",
    },
    // backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#858D9D !important",
  },
};

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [state, setState] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const [billingState, setBillingState] = useState("");
  const [billingCityOptions, setBillingCityOptions] = useState([]);
  const [isSame, setIsSame] = useState(false);

  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    shippingStreetAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingPostalCode: "",
    shippingSuite: "",
    billingPostalCode: "",
    billingStreetAddress: "",
    billingCity: "",
    billingState: "",
    billingSuite: "",
    dob: "",
    sex: "",
    heightFeet: "",
    heightInches: "",
    weight: "",
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const defaultValues = {
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    shippingStreetAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingPostalCode: "",
    shippingSuite: "",
    billingPostalCode: "",
    billingStreetAddress: "",
    billingCity: "",
    billingState: "",
    billingSuite: "",
    dob: "",
    sex: "",
    heightFeet: "",
    heigthInches: "",
    weight: "",
  };

  useEffect(() => {
    const cities = states[state] || [];
    console.log(cities);
    setCityOptions(
      cities.map((city) => ({
        label: city,
        id: city,
      }))
    );

    if (isSame) {
      setBillingCityOptions(
        cities.map((city) => ({
          label: city,
          id: city,
        }))
      );
    }
  }, [state]);
  useEffect(() => {
    const cities = states[billingState] || [];
    console.log(cities);
    setBillingCityOptions(
      cities.map((city) => ({
        label: city,
        id: city,
      }))
    );
  }, [billingState]);

  const onSubmit = async (values) => {
    console.log(values);

    if (values?.password!=="" && values.password !== values.confirmPassword) {
      toastMessage("Passwords do not match", "error");
      return;
    }

    if (isSame) {
      values.billingStreetAddress = values.shippingStreetAddress;
      values.billingCity = values.shippingCity;
      values.billingState = values.shippingState;
      values.billingSuite = values.shippingSuite;
      values.billingPostalCode = values.shippingPostalCode;
    }

    try {
      setLoading(true);
      const { data } = await callAxios("put", "auth/updateProfile", values);
      console.log(data);
      setLoading(false);
      navigate("/");
      toastMessage("Profile Updated Successfully", "success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage("Something went wrong", "error");
    }
  };

  const getUserLoggedInUserDetails = async () => {
    try {
      setLoading(true);

      const { data } = await callAxios("get", "auth/getLoggedInUserDetails");

      console.log({
        ...data?.user,
        password: "",
      });
      setLoading(false);
      setFormValues(data?.user);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage("Something went wrong", "error");
    }
  };

  useEffect(() => {
    getUserLoggedInUserDetails();
  }, []);

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#17b3ab" />
      <div className="hero-about-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-about-text text-center">
                <h2>Profile</h2>
                <h4>
                  <span>Home / </span>
                  <a href="#">Profile</a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contuct-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-form-area">
                <h3>Edit your profile</h3>
                <FormContainer
                  defaultValues={defaultValues}
                  onSuccess={onSubmit}
                  values={{
                    email: formValues.email,
                    password: formValues.password,
                    confirmPassword: formValues.confirmPassword,
                    firstName: formValues.firstName,
                    lastName: formValues.lastName,
                    phoneNo: formValues.phoneNo,
                    shippingStreetAddress: formValues.shippingStreetAddress,
                    shippingCity: formValues.shippingCity,
                    shippingState: formValues.shippingState,
                    shippingPostalCode: formValues.shippingPostalCode,
                    shippingSuite: formValues.shippingSuite,
                    billingPostalCode: isSame
                      ? formValues.shippingPostalCode
                      : formValues.billingPostalCode,
                    billingStreetAddress: isSame
                      ? formValues.shippingStreetAddress
                      : formValues.billingStreetAddress,
                    billingCity: isSame
                      ? formValues.shippingCity
                      : formValues.billingCity,
                    billingState: isSame
                      ? formValues.shippingState
                      : formValues.billingState,
                    billingSuite: isSame
                      ? formValues.shippingSuite
                      : formValues.billingSuite,
                    dob: formValues.dob,
                    sex: formValues.sex,
                    heightFeet: formValues.heightFeet,
                    heightInches: formValues.heightInches,
                    weight: formValues.weight,
                  }}
                >
                  <div className="tw-w-full tw-flex tw-flex-col tw-gap-2 ">
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <TextFieldElement
                        name="firstName"
                        placeholder="Enter your first name"
                        required
                        label="First Name"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            firstName: e.target.value,
                          });
                        }}
                      />
                      <TextFieldElement
                        name="lastName"
                        placeholder="Enter your last name"
                        required
                        label="Last Name"
                        variant="outlined"
                        sx={styles.root}
                        fullWidth
                        size="small"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            lastName: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <TextFieldElement
                        name="email"
                        placeholder="Enter your email"
                        required
                        type="email"
                        label="Email"
                        variant="outlined"
                        sx={styles.root}
                        fullWidth
                        size="small"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            email: e.target.value,
                          });
                        }}
                      />
                      <TextFieldElement
                        name="phoneNo"
                        placeholder="Enter your phone number"
                        required
                        type="number"
                        label="Phone"
                        variant="outlined"
                        sx={styles.root}
                        fullWidth
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            phoneNo: e.target.value,
                          });
                        }}
                        size="small"
                        validation={{
                          validate: (value) => {
                            console.log(value);
                            let regex = /^[0-9]{10}$/;
                            if (regex.test(value)) {
                              return true;
                            } else {
                              return "Phone enter a valid phone no ";
                            }
                          },
                        }}
                      />
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <TextFieldElement
                        name="password"
                        size="small"
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        placeholder="Your password"
                        sx={styles.root}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            password: e.target.value,
                          });
                        }}
                        validation={{
                          validate: (value) => {
                            console.log(value);
                            let regex = /^(?=.*[0-9])(?=.*[@#$%^&+=!])(.{8,})$/;
                            if (regex.test(value)) {
                              return true;
                            } else {
                              return "Password must contain atleast 8 characters and a special character and a number (0-9) ";
                            }
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff fontSize="small" />
                                ) : (
                                  <Visibility fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextFieldElement
                        name="confirmPassword"
                        size="small"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            confirmPassword: e.target.value,
                          });
                        }}
                        fullWidth
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm password"
                        sx={styles.root}
                        validation={{
                          validate: (value, values) => {
                            console.log(values);

                            if (value === values?.password) {
                              console.log("Password Matched");

                              return true;
                            } else {
                              return "Password does not match";
                            }
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff fontSize="small" />
                                ) : (
                                  <Visibility fontSize="small" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}

                        // onBlur={confirmPasswordBlurHandler}
                        // helperText={
                        //   errors.confirmPassword && "Password does not match"
                        // }
                      />
                    </div>
                    <div className="tw-grid tw-grid-cols-1 tw-mt-2 md:tw-grid-cols-3 tw-gap-4">
                      <TextFieldElement
                        name="dob"
                        placeholder="Enter your Date Of Birth"
                        required
                        type="date"
                        label="Date Of Birth"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        sx={styles.root}
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            dob: e.target.value,
                          });
                        }}
                      />
                      <SelectElement
                        name="heightFeet"
                        placeholder="Enter Height in feet"
                        required
                        label="Height in feet"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        options={[
                          {
                            label: "3 Feet",
                            id: 3,
                          },
                          {
                            label: "4 Feet",
                            id: 4,
                          },
                          {
                            label: "5 Feet",
                            id: 5,
                          },
                          {
                            label: "6 Feet",
                            id: 6,
                          },
                          {
                            label: "7 Feet",
                            id: 7,
                          },
                        ]}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            heightFeet: e,
                          });
                        }}
                      />
                      <SelectElement
                        name="heightInches"
                        placeholder="Enter Height in inches"
                        required
                        label="Height in inches"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        options={[
                          {
                            label: "1 Inch",
                            id: 1,
                          },
                          {
                            label: "2 Inch",
                            id: 2,
                          },
                          {
                            label: "3 Inch",
                            id: 3,
                          },
                          {
                            label: "4 Inch",
                            id: 4,
                          },
                          {
                            label: "5 Inch",
                            id: 5,
                          },
                          {
                            label: "6 Inch",
                            id: 6,
                          },
                          {
                            label: "7 Inch",
                            id: 7,
                          },
                          {
                            label: "8 Inch",
                            id: 8,
                          },
                          {
                            label: "9 Inch",
                            id: 9,
                          },
                          {
                            label: "10 Inch",
                            id: 10,
                          },
                          {
                            label: "11 Inch",
                            id: 11,
                          },
                          {
                            label: "12 Inch",
                            id: 12,
                          },
                        ]}
                        onChange={(e) => {
                          console.log(e);
                          setFormValues({
                            ...formValues,
                            heightInches: e,
                          });
                        }}
                      />
                    </div>
                    <div className="tw-grid tw-grid-cols-1 tw-mt-2 md:tw-grid-cols-2 tw-gap-4">
                      <TextFieldElement
                        name="weight"
                        placeholder="Enter your Weight(lbs)"
                        required
                        type="number"
                        label="Weight(lbs)"
                        variant="outlined"
                        sx={styles.root}
                        fullWidth
                        size="small"
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            weight: e.target.value,
                          });
                        }}
                      />
                      <SelectElement
                        name="sex"
                        placeholder="Select your Sex"
                        required
                        label="Sex"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        options={[
                          {
                            label: "Male",
                            id: "Male",
                          },
                          {
                            label: "Female",
                            id: "Female",
                          },
                          {
                            label: "Other",
                            id: "Other",
                          },
                        ]}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            sex: e,
                          });
                        }}
                      />
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <h6 className="tw-text-lg tw-text-[#17b3ab] tw-font-semibold tw-mb-1">
                        Shipping Address
                      </h6>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <TextFieldElement
                        name="shippingStreetAddress"
                        placeholder="Enter your shipping address"
                        required
                        label="Address"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            shippingStreetAddress: e.target.value,
                          });
                        }}
                      />
                      <TextFieldElement
                        name="shippingSuite"
                        placeholder="Apt,Suite(Optional)"
                        label="Suite,Apt(Optional)"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            shippingSuite: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
                      <SelectElement
                        name="shippingState"
                        placeholder="Enter your shipping state"
                        required
                        label="State"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        onChange={(e) => {
                          setState(e);
                          setFormValues({
                            ...formValues,
                            shippingState: e,
                          });
                        }}
                        fullWidth
                        options={Object.keys(states).map((state) => ({
                          id: state,
                          label: state,
                        }))}
                      />
                      <SelectElement
                        name="shippingCity"
                        placeholder="Select Shipping City"
                        label="City"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            shippingCity: e,
                          });
                        }}
                        options={cityOptions}
                      />
                      <TextFieldElement
                        name="shippingPostalCode"
                        placeholder="Enter your postal code"
                        required
                        type="number"
                        label="Postal Code"
                        variant="outlined"
                        sx={styles.root}
                        fullWidth
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            shippingPostalCode: e.target.value,
                          });
                        }}
                        size="small"
                        validation={{
                          validate: (value) => {
                            console.log(value);
                            let regex = /^[0-9]{5}$/;
                            if (regex.test(value)) {
                              return true;
                            } else {
                              return "Phone enter a valid postal code ";
                            }
                          },
                        }}
                      />
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <h6 className="tw-text-lg tw-text-[#17b3ab] tw-font-semibold tw-mb-1">
                        Billing Address
                      </h6>
                    </div>
                    <div className="tw-flex tw-items-center gap-2">
                      <Checkbox
                        checked={isSame}
                        onChange={(e) => {
                          setIsSame(e.target.checked);
                        }}
                      />
                      <p className="tw-font-medium tw-text-black tw-text-sm">
                        Same as Shipping Address
                      </p>
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                      <TextFieldElement
                        name="billingStreetAddress"
                        placeholder="Enter your billing address"
                        required
                        label="Address"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        disabled={isSame}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            billingStreetAddress: e.target.value,
                          });
                        }}
                      />
                      <TextFieldElement
                        name="billingSuite"
                        placeholder="Apt,Suite(Optional)"
                        label="Suite,Apt(Optional)"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        disabled={isSame}
                        fullWidth
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            billingSuite: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
                      <SelectElement
                        name="billingState"
                        placeholder="Enter your shipping state"
                        required
                        label="State"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        disabled={isSame}
                        onChange={(e) => {
                          setBillingState(e);
                          setFormValues({
                            ...formValues,
                            billingState: e,
                          });
                        }}
                        fullWidth
                        options={Object.keys(states).map((state) => ({
                          id: state,
                          label: state,
                        }))}
                      />
                      <SelectElement
                        name="billingCity"
                        placeholder="Select Shipping City"
                        label="City"
                        variant="outlined"
                        sx={styles.root}
                        size="small"
                        fullWidth
                        disabled={isSame}
                        options={billingCityOptions}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            billingCity: e,
                          });
                        }}
                      />
                      <TextFieldElement
                        name="billingPostalCode"
                        placeholder="Enter your postal code"
                        required
                        type="number"
                        label="Postal Code"
                        variant="outlined"
                        disabled={isSame}
                        sx={styles.root}
                        fullWidth
                        size="small"
                        validation={{
                          validate: (value) => {
                            console.log(value);
                            let regex = /^[0-9]{5}$/;
                            if (regex.test(value)) {
                              return true;
                            } else {
                              return "Phone enter a valid postal code ";
                            }
                          },
                        }}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            billingPostalCode: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <button className="signup-button tw-mt-8" type="submit">
                    Submit Now!
                  </button>
                </FormContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
