import React, { useState, useEffect } from "react";
import Hero from "./Hero";
import axios from "axios";
import { backendUrl } from "../../utils/axios";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Medicines = () => {
  const [medicines, setMedicines] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const medications = [
    {
      name: "Abacavir / Lamivudine (Generic for Epzicom)",
      form: "Tablet",
      retailPrice: "$1,096.10",
      ourPrice: "$38.30",
      savings: "$1,057.80",
    },
    {
      name: "Abacavir Sulfate (Generic for Ziagen)",
      form: "Bottle of Solution",
      retailPrice: "$157.00",
      ourPrice: "$38.44",
      savings: "$118.56",
    },
    {
      name: "Abiraterone Acetate (Generic for Zytiga)",
      form: "Tablet",
      retailPrice: "$1,093.20",
      ourPrice: "$26.90",
      savings: "$1,066.30",
    },
  ];

  const getAllMedicines = async () => {
    try {
      const { data } = await axios.get(
        `${backendUrl}/medicine/getAllMedicine?search=${search}&page=${page}`
      );

      setMedicines(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllMedicines();
  }, [search]);

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
      <Hero />
      <div className="tw-bg-green-100 tw-p-8 tw-rounded-lg tw-mb-8 tw-relative">
        <h2 className="tw-text-2xl tw-font-bold tw-text-center tw-mb-6">
          Start your search
        </h2>

        <div className="tw-flex tw-items-center tw-justify-center">
          <div className="tw-flex tw-gap-4 tw-items-center tw-border-2 tw-border-green-400 tw-rounded-full tw-bg-white tw-py-2 tw-px-4 tw-w-full tw-max-w-lg">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Magnifying Glass</title>
              <path
                d="M18.9999 19L14.6499 14.65M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
                stroke="#535762"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="tw-w-full tw-border-none tw-outline-none tw-text-gray-600"
              placeholder="Search by brand name, generic name, or by health condition"
            />
          </div>
        </div>

        {/* Add decorative elements */}
      </div>
      <div className="tw-px-4 tw-py-8">
        <h2 className="tw-text-2xl tw-font-bold tw-text-center tw-mb-4">
          All Medications
        </h2>
        <p className="tw-text-center tw-text-gray-500 tw-mb-6">
          Is your medication out of stock?
        </p>

        <div className="tw-overflow-x-auto">
          <table className="tw-min-w-full tw-bg-white tw-border tw-rounded-lg">
            <thead>
              <tr className="tw-bg-green-100 tw-text-left">
                <th className="tw-px-4 tw-py-2 tw-font-semibold tw-text-gray-600">
                  Medication
                </th>
                <th className="tw-px-4 tw-py-2 tw-font-semibold tw-text-gray-600">
                  Form
                </th>
                <th className="tw-px-4 tw-py-2 tw-font-semibold tw-text-gray-600">
                  Retail price
                </th>
                <th className="tw-px-4 tw-py-2 tw-font-semibold tw-text-gray-600">
                  Our price
                </th>
                <th className="tw-px-4 tw-py-2 tw-font-semibold tw-text-gray-600">
                  Savings
                </th>
                <th className="tw-px-4 tw-py-2 tw-font-semibold tw-text-gray-600">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {medicines.map((med, index) => (
                <tr key={index} className="tw-border-t">
                  <td className="tw-px-4 tw-py-2 tw-text-green-600 tw-font-medium tw-underline hover:tw-text-green-400 tw-cursor-pointer">
                    {med?.name}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-text-gray-600">
                    {med?.forms[0]?.name}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-text-gray-600 tw-line-through">
                    $
                    {med?.forms[0]?.strengths[0]?.quantities[0]?.retailPrice ||
                      0}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-text-gray-600">
                    ${med?.forms[0]?.strengths[0]?.quantities[0]?.price || 0}
                  </td>
                  <td className="tw-px-4 tw-py-2 tw-text-green-600">
                    Save $
                    {Number(
                      med?.forms[0]?.strengths[0]?.quantities[0]?.retailPrice ||
                        0
                    ) -
                      Number(
                        med?.forms[0]?.strengths[0]?.quantities[0]?.price || 0
                      )}
                  </td>
                  <td className="tw-px-4 tw-py-2">
                    <button
                      className="tw-bg-white tw-border tw-border-green-500 tw-text-green-500 tw-rounded-full tw-px-4 tw-py-1 hover:tw-bg-green-500 hover:tw-text-white"
                      onClick={() => navigate(`/medicine/${med?._id}`)}
                    >
                      Get Started
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="tw-w-full tw-flex tw-justify-end tw-items-center tw-p-4">
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={(e, value) => setPage(value)}
          />
        </div>
      </div>
    </div>
  );
};

export default Medicines;
