import React from 'react'

const Footer = () => {
  return (
    <footer>
    <div className="footer-area pt-50">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="footer-box">
              <div className="logo">
                <a href="index.html">
                  <img src="assets/img/footer-logo.png" alt="img" />
                </a>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
              </div>
              <address>
                <ul className="text-foot">
                  <li>
                    <i className="icofont-google-map" /> 11 Georgian Rd, 58/A{" "}
                    <br />
                    New York City
                  </li>
                  <li>
                    <i className="icofont-phone" />{" "}
                    <a href="tel:12396661985">+123-9666 1985</a>
                  </li>
                  <li>
                    <i className="icofont-envelope" />{" "}
                    <a href="mailto:info@medics.com">info@primegenrx.com</a>
                  </li>
                </ul>
              </address>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="footer-box">
              <h2>Explore</h2>
              <ul className="text-foot">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Testimonial</a>
                </li>
                <li>
                  <a href="#">Doctor’s Team</a>
                </li>
                <li>
                  <a href="#">Contact us</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-box another">
              <h2>Links</h2>
              <ul className="text-foot">
                <li>
                  <a href="#">Make An Appointment</a>
                </li>
                <li>
                  <a href="#">Free Consultation</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
                <li>
                  <a href="#">Terms &amp; Condition</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-box another">
              <h2>Subscribe</h2>
              <p style={{ padding: 0 }}>
                Subscribe to get the Latest News from us.
              </p>
              <form className="newletter_form_outer">
                <div className="input-group">
                  <input
                    className="form-control"
                    id="email"
                    placeholder="Enter Email Here"
                  />
                  <button type="button">
                    <i className="icofont-paper-plane" />
                  </button>
                </div>
              </form>
              <ul className="social">
                <li>
                  <a href="#">
                    <i className="icofont-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icofont-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icofont-instagram" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="icofont-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <div className="container">
        <p>
          2024 © all rights reserved by <a href="#">Primegenrx</a>
        </p>
      </div>
    </div>
    <a href="#" className="go-top">
      <i className="fas fa-arrow-up" />
    </a>
  </footer>
  
  )
}

export default Footer