import React from 'react'

const Hero = () => {
  return (
    <div className="hero-area position-relative">
    <img src="assets/img/homepage2/hero-img.png" alt="" className="hero-img" />
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="hero-containt">
           
            <h3>
              Close Compare Price <br /> Game Best offer is here
            </h3>
       
            <a href="#" className="theme-btn">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Hero