import React from 'react'

const Hero = () => {
  return (
    <div className="hero-about-area">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="hero-about-text text-center">
            <h2>Contact Your Doctor</h2>
            <h4>
              <span>Home / </span>Contact Your Doctor
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Hero