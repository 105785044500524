import React from "react";

const Info = () => {
  return (
    <div className="info-area">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-lg-4 col-md-4">
            <div className="single-info">
              <i className="icofont-map-pins" />
              <p>11 Georgian Rd, 58/A, New York City</p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="single-info cl2">
              <i className="icofont-envelope" />
              <p>
                <a href="mailto:info@info.com">info@primegenrx.com</a>
                <br />
                <a href="info%40medics.html">info@primegenrx.com</a>
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="single-info cl3">
              <i className="icofont-mobile-phone" />
              <div className="info-details text-white">
                <p>
                  <a href="tel:01234567890">01213-456-7890</a>
                </p>
                <p>
                  <a href="tel:01234567890">01213-456-7890</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
