import React from 'react'

const DeliveryBanner = () => {
  return (
<div className="baner-area">
  <div className="container">
    <div className="row">
      <div className="col-lg-3 col-md-4">
        <div className="baner-containt">
          <h4>
            Shop Online <br />
            or In store Get
          </h4>
          <a href="#">FREE</a>
          <h3>
            {" "}
            <span>Delivery</span> <br />
            at Your Door
          </h3>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default DeliveryBanner