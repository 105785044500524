import React from 'react'

const Brands = () => {
  return (
    <div className="brand-area" style={{
        marginTop:"2rem"
    }} >
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title">
          <h3>Shop By Brands</h3>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="brand-fl">
          <div className="single-brand">
            <img src="images/brands/brand1.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand2.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand3.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand4.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand5.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand6.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand7.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand8.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand9.png" alt="" />
          </div>
          <div className="single-brand">
            <img src="images/brands/brand10.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Brands