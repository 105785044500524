import React from 'react';

const FAQ = () => {
  return (
    <div className="features-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="fea-title text-center">
              <h2>Have You Any Questions?</h2>
              <p>
                Welcome to our pharmacy! If you have any questions, feel free to
                browse through our frequently asked questions below.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="features-all-content">
              <div className="features-content">
                <div className="features-info">
                  <img src="assets/img/about/cn.png" alt="Pharmacy" />
                </div>
                <div className="features-text">
                  <h3>How Can I Refill My Prescription?</h3>
                  <p>
                    You can refill your prescription by logging into your account
                    on our website, calling our customer service, or visiting our
                    pharmacy in person. Please have your prescription details
                    ready.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="features-all-content">
              <div className="features-content">
                <div className="features-info">
                  <img src="assets/img/about/cn.png" alt="Pharmacy" />
                </div>
                <div className="features-text">
                  <h3>What Should I Do If I Miss a Dose?</h3>
                  <p>
                    If you miss a dose, take it as soon as you remember. If it is
                    close to the time of your next dose, skip the missed dose and
                    resume your regular dosing schedule. Do not take a double
                    dose to make up for a missed one.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="features-all-content">
              <div className="features-content">
                <div className="features-info">
                  <img src="assets/img/about/cn.png" alt="Pharmacy" />
                </div>
                <div className="features-text">
                  <h3>Can I Transfer My Prescription to Your Pharmacy?</h3>
                  <p>
                    Yes, you can transfer your prescription to our pharmacy. Simply
                    provide us with the details of your current pharmacy and your
                    prescription, and we will take care of the rest.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="features-all-content">
              <div className="features-content">
                <div className="features-info">
                  <img src="assets/img/about/cn.png" alt="Pharmacy" />
                </div>
                <div className="features-text">
                  <h3>Do You Offer Delivery Services?</h3>
                  <p>
                    Yes, we offer delivery services for your convenience. You can
                    place an order online or over the phone, and we will deliver
                    your medications to your doorstep.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="features-all-content" style={{ borderBottom: "none" }}>
              <div className="features-content">
                <div className="features-info">
                  <img src="assets/img/about/cn.png" alt="Pharmacy" />
                </div>
                <div className="features-text">
                  <h3>How Can I Consult with a Pharmacist?</h3>
                  <p>
                    You can consult with a pharmacist by visiting our pharmacy,
                    calling our customer service, or scheduling an online
                    consultation through our website.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="features-all-content" style={{ borderBottom: "none" }}>
              <div className="features-content">
                <div className="features-info">
                  <img src="assets/img/about/cn.png" alt="Pharmacy" />
                </div>
                <div className="features-text">
                  <h3>What Are Your Pharmacy's Operating Hours?</h3>
                  <p>
                    Our pharmacy is open from Monday to Saturday, 9 AM to 9 PM,
                    and on Sunday from 10 AM to 6 PM. Please visit our website or
                    call us for any holiday hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
