import React from 'react'
import Hero from './Hero'
import ProductArea from './ProductArea'
import Products from './Products'
import DeliveryBanner from './DeliveryBanner'
import Brands from './Brands'
import Info from './Info'
import About from './About'
import FAQ from './FAQ'
import Services from './Services'

const Home = () => {
  return (
    <>
    <Hero />
    <ProductArea />
    <About />
    <Products />
    <DeliveryBanner />
    <Brands />
    <FAQ />
    <Services />
    <Info />
    </>
  )
}

export default Home