import React, { useState, useEffect } from "react";
import axios from "axios";
import { backendUrl } from "../../utils/axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const MedicineDetail = () => {
  const navigate = useNavigate();
  const [medicine, setMedicine] = useState(null);

  const [selectedForm, setSelectedForm] = useState(null); // Default to first form
  const [selectedStrength, setSelectedStrength] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(null); // Default to first quantity

  const handleFormChange = (form) => {
    setSelectedForm(form);
    setSelectedStrength(form?.strengths[0]); // Default to first strength

    setSelectedQuantity(form?.strengths[0]?.quantities[0]); // Default to first quantity
  };

  const handleStrengthChange = (strength) => {
    setSelectedStrength(strength);
    setSelectedQuantity(strength?.quantities[0]); // Default to first quantity
  };

  // Handle quantity selection
  const handleQuantityChange = (quantity) => {
    setSelectedQuantity(quantity);
  };

  const [totalPrice, setTotalPrice] = useState(0);

  // Effect to update total price when quantity changes
  useEffect(() => {
    if (selectedQuantity) {
      setTotalPrice(selectedQuantity.price);
    }
  }, [selectedQuantity]);

  const { id } = useParams();

  const getMedicine = async () => {
    try {
      const { data } = await axios.get(
        `${backendUrl}/medicine/getMedicineById/${id}`
      );
      console.log(data);
      setMedicine(data.data);
      setSelectedForm(data.data.forms[0]); // Set default form
      setSelectedStrength(data.data.forms[0].strengths[0]); // Set default strength
      setSelectedQuantity(data.data.forms[0].strengths[0].quantities[0]); // Set default quantity
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMedicine();
  }, [id]);

  return (
    <>
      {medicine && (
        <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
          <div className="tw-px-6 tw-py-8">
            <div className="tw-text-sm tw-text-green-600 tw-mb-4">
              <a href="#">All Medications</a> &gt; {medicine?.name}
            </div>

            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-8">
              <div className="tw-col-span-2">
                <h1 className="tw-text-3xl tw-font-bold tw-text-gray-800">
                  {medicine?.name} (Generic for {medicine?.genericFor})
                </h1>

                <div className="tw-mt-4 tw-flex tw-items-center tw-space-x-4">
                  <div className="tw-bg-gray-100 tw-p-4 tw-rounded">
                    <img
                      src="/assets/img/services/s-7.png"
                      alt="Medicine"
                      className="tw-w-16 tw-h-16"
                    />
                  </div>
                  <div className="tw-bg-green-100 tw-px-4 tw-py-2 tw-text-green-800 tw-rounded-md">
                    <i className="tw-font-bold">Prescription Required</i>
                  </div>
                </div>

                <div className="tw-mt-6">
                  <h3 className="tw-font-bold">
                    Why does my medication look different?
                  </h3>
                  <p className="tw-text-gray-600 tw-mt-2">
                    Product images are for illustrative purposes only. We cannot
                    guarantee a specific manufacturer. The medication you
                    receive may look different, but the drug, strength, and
                    ingredients are the same.
                  </p>
                </div>

                <div className="tw-mt-8 tw-bg-green-100 tw-p-6 tw-rounded-lg">
                  <h2 className="tw-text-lg tw-font-semibold tw-text-green-800">
                    Contact your doctor for a prescription
                  </h2>
                  <p className="tw-mt-2 tw-text-gray-700">
                    Create an account, and receive an email once we receive your
                    prescription. Don't see the quantity you need? We will fill
                    the amount prescribed by your doctor.
                  </p>
                  <button onClick={()=>navigate("/contact-your-doctor")} className="tw-mt-4 tw-bg-green-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-full"  >
                    Get Started
                  </button>
                </div>
              </div>

              <div className="tw-bg-white tw-shadow-lg tw-p-6 tw-rounded-lg">
                <h2 className="tw-text-2xl tw-font-bold tw-text-gray-800">
                  Price Calculator
                </h2>

                <div className="tw-mt-4">
                  <h3 className="tw-font-bold">{medicine?.name}</h3>
                  <p className="tw-text-gray-600">
                    {selectedForm?.name} • {selectedStrength?.value} • {selectedQuantity?.count} count
                  </p>
                  <p className="tw-text-3xl tw-font-bold tw-text-green-600">
                    ${selectedQuantity?.price}
                  </p>
                </div>

                <div className="tw-mt-6">
                  <h4 className="tw-font-semibold">Form</h4>
                  <div className="tw-grid tw-grid-cols-3 tw-w-full tw-gap-4 tw-mt-2">
                    {medicine.forms.map((form) => (
                      <div
                        key={form._id}
                        onClick={() => handleFormChange(form)}
                        className={` tw-w-full tw-text-sm tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw tw-px-3 tw-py-1 tw-rounded-md   tw-mt-2 ${
                          selectedForm === form ? "tw-bg-green-600 tw-text-white" : "tw-bg-green-100 tw-text-green-800"
                        }`}
                      >
                        {form?.name}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="tw-mt-6">
                  <h4 className="tw-font-semibold">Strength</h4>
                  <div className="tw-grid tw-grid-cols-3 tw-w-full tw-gap-4 tw-mt-2">
                  {selectedForm.strengths.map((strength) => (
                      <div
                        key={strength._id}
                        onClick={() => handleStrengthChange(strength)}
                        className={` tw-w-full tw-text-sm tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw tw-px-3 tw-py-1 tw-rounded-md   tw-mt-2 ${
                          strength?._id === selectedStrength?._id ? "tw-bg-green-600 tw-text-white" : "tw-bg-green-100 tw-text-green-800"
                        }`}
                      >
                        {strength?.value}
                      </div>
                    ))}
                    </div>
                </div>

                <div className="tw-mt-6">
                  <h4 className="tw-font-semibold">Quantity</h4>
                  <div className="tw-grid tw-grid-cols-3 tw-w-full tw-gap-4 tw-mt-2">
                  {selectedStrength?.quantities.map((quantity) => (
                      <div
                        key={quantity._id}
                        onClick={() => handleQuantityChange(quantity)}
                        className={` tw-w-full tw-text-sm tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw tw-px-3 tw-py-1 tw-rounded-md   tw-mt-2 ${
                          selectedQuantity?._id === selectedQuantity?._id ? "tw-bg-green-600 tw-text-white" : "tw-bg-green-100 tw-text-green-800"
                        }`}
                      >
                        {quantity?.count}
                      </div>
                    ))}
                  </div>
                </div>

                <p className="tw-text-sm tw-text-gray-500 tw-mt-6">
                  *final price shown at checkout
                </p>
              </div>
            </div>

            <div className="tw-mt-12">
              <h2 className="tw-text-2xl tw-font-bold">Transparent Pricing</h2>
              <p className="tw-mt-2 tw-text-gray-700">
                We think you should know how much your medications cost and why.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MedicineDetail;
