import React from "react";

const Services = () => {
  return (
    <div className="Medical-Services-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="service-title text-center">
              <h2>COMMON CONDITIONS SAVINGS WITH PRIMEGENRX PHARMACY</h2>
              <p>Average savings by condition with Primegenrx coupons</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="medi-service-box">
              <div className="explore">
                <div className="dental-care">
                  <img src="assets/img/services/s-3.png" alt="" />
                </div>
                <div className="care-text">
                  <h4>
                    <a href="services-details.v.1.html">Erectile Dysfunction</a>
                  </h4>
                  <p>91% average discount</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="medi-service-box">
              <div className="explore">
                <div className="dental-care">
                  <img src="assets/img/services/s-2.png" alt="" />
                </div>
                <div className="care-text">
                  <h4>
                    <a href="services-details.v.1.html">Depression</a>
                  </h4>
                  <p>67% average discount</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="medi-service-box">
              <div className="explore">
                <div className="dental-care">
                  <img src="assets/img/services/s-6.png" alt="" />
                </div>
                <div className="care-text">
                  <h4>
                    <a href="services-details.v.1.html">Hypertension</a>
                  </h4>
                  <p>58% average discount</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="medi-service-box">
              <div className="explore">
                <div className="dental-care">
                  <img src="assets/img/services/s-5.png" alt="" />
                </div>
                <div className="care-text">
                  <h4>
                    <a href="services-details.v.1.html">Weight Loss</a>
                  </h4>
                  <p>57% average discount</p>
                </div>
              </div>
            </div>
          </div>
      
        </div>
      </div>
    </div>
  );
};

export default Services;
