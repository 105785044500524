import React from "react";

const ProductArea = () => {
  return (
    <div className="product-area">
      <img
        src="assets/img/homepage2/round-sheap.png"
        alt=""
        className="round-sheap"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="quality-fl">
              <div className="quality-text">
                <span>24/7 Available</span>
                <h3>
                  Best Quality <br />
                  Pharmacy Store
                </h3>
                <p>
                  Welcome TO PrimeGenRx is an organization dedicated to giving
                  its clients a safe and dependable experience while believing
                  in providing excellent health care. We are committed to
                  providing you and your loved ones with the necessary care to
                  care for your health, which is our first priority.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="qiality-pp">
              <img
                src="assets/img/homepage2/pluse-sheap.png"
                alt=""
                className="pluse-sheap"
              />
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="single-quality">
                    <img src="assets/img/homepage2/q1.png" alt="" />
                    <h4>Online Pharmacy</h4>
                    <p>
                      Search for your medication online by name or condition
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-quality qb2">
                    <img src="assets/img/homepage2/q2.png" alt="" />
                    <h4>Medicine Supply</h4>
                    <p>Place your order online & by phone or by live chat</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-quality qb3">
                    <img src="assets/img/homepage2/q3.png" alt="" />
                    <h4>Send Prescription</h4>
                    <p>Send us your (Rx) prescription to process your order </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="single-quality qb4">
                    <img src="assets/img/homepage2/q4.png" alt="" />
                    <h4>Order Complete</h4>
                    <p>
                      Order complete! You will receive your medications by mail.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductArea;
