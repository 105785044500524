import React from "react";

const About = () => {
  return (
    <div className="experience-area">
      <img
        className="shape shape-3"
        src="assets/img/background/abt-doc.jpg"
        alt=""
      />
      <img
        className="shape shape-2"
        src="assets/img/background/exper-shape.png"
        alt=""
      />
      <div className="container">
        <img className="shape shape-1" src="assets/img/about/nars.png" alt="" />
        <div className="row">
          <div className="col-lg-6 offset-lg-6 offset-md-6">
            <div className="exper-all-content">
              <div className="exper-title">
                <h2>About Us</h2>
              </div>
              <div className="exper-content">
                <p>Welcome TO PrimeGenRx Pharmacy</p>
              </div>
              <div className="exper-text">
                <p>
                  Welcome TO PrimeGenRx is an organization dedicated to giving
                  its clients a safe and dependable experience while believing
                  in providing excellent health care. We are committed to
                  providing you and your loved ones with the necessary care to
                  care for your health, which is our first priority.
                </p>
                <p>
                  We have created a platform that enables you to easily acquire
                  a wide variety of prescription medications and other health
                  supplies at the lowest possible cost. Making our customers as
                  convenient as possible is our first goal. So, you don't have
                  to worry about your prescription running out because of
                  factors like high traffic, rain, extreme summer temperatures,
                  or excessive chilling.rimeGenRx Pharmacy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
