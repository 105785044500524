import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

let user;
let token;
let isLoggedIn = false;
if (typeof window !== "undefined") {
  // Perform localStorage action
  user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "")
    : null;
  token = localStorage.getItem("token");
}

console.log(user, isLoggedIn);

const initialState = {
  user, // for user object
  token,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      logout: (state) => {
        state.user = null;
        state.token = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      },
      login: (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.token;
        localStorage.setItem("user", JSON.stringify(action.payload.user));
        localStorage.setItem("token", action.payload.token);
      },
    },
  });
  export const { logout,login } = userSlice.actions;
  // export const selectUser = (state: { user: any }) => state.user;
  
  export default userSlice.reducer;
  