import React from 'react'

const Products = () => {
  return (
    <div className="featured-area position-relative">
    <img src="assets/img/homepage2/pluse-2.png" alt="" className="pluse-2" />
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h3>Featured Products</h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="featured-teb">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  All
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Medical Equipment
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-contact-tab"
                  data-toggle="pill"
                  href="#pills-contact"
                  role="tab"
                  aria-controls="pills-contact"
                  aria-selected="false"
                >
                  Medicine &amp; Health
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-contact-tab2"
                  data-toggle="pill"
                  href="#pills-contact2"
                  role="tab"
                  aria-controls="pills-contact2"
                  aria-selected="false"
                >
                  Personal Care
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="pills-contact-tab3"
                  data-toggle="pill"
                  href="#pills-contact3"
                  role="tab"
                  aria-controls="pills-contact3"
                  aria-selected="false"
                >
                  Baby Care
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="inner-featured">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f7.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f1.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Thermometer</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f2.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin D</h3>
                          <h4>$25.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f3.png" alt="" />
                          </div>
                          <span>-10% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Sugar Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f4.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Hand Sanitizer</h3>
                          <h4>$30.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f5.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin C</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f6.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>BP Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f7.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="inner-featured">
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f1.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Thermometer</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f2.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin D</h3>
                          <h4>$25.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f3.png" alt="" />
                          </div>
                          <span>-10% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Sugar Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f4.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Hand Sanitizer</h3>
                          <h4>$30.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f5.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin C</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f6.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>BP Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f7.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f8.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Disposable Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <div className="inner-featured">
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f7.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f2.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin D</h3>
                          <h4>$25.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f3.png" alt="" />
                          </div>
                          <span>-10% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Sugar Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f4.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Hand Sanitizer</h3>
                          <h4>$30.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f5.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin C</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f6.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>BP Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f7.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f8.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Disposable Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact2"
                role="tabpanel"
                aria-labelledby="pills-contact-tab2"
              >
                <div className="inner-featured">
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f2.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin D</h3>
                          <h4>$25.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f1.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Thermometer</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f3.png" alt="" />
                          </div>
                          <span>-10% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Sugar Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f4.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Hand Sanitizer</h3>
                          <h4>$30.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f5.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin C</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f6.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>BP Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f7.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f8.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Disposable Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-contact3"
                role="tabpanel"
                aria-labelledby="pills-contact-tab3"
              >
                <div className="inner-featured">
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f2.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin D</h3>
                          <h4>$25.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f3.png" alt="" />
                          </div>
                          <span>-10% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Sugar Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f1.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Thermometer</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f4.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Hand Sanitizer</h3>
                          <h4>$30.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f5.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Vitamin C</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f6.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>BP Monitor</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f7.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4">
                      <div className="single-featured">
                        <div className="singl-top position-relative">
                          <div className="feet-img">
                            <img src="assets/img/homepage2/f8.png" alt="" />
                          </div>
                          <span>-20% </span>
                          <a className="love" href="#">
                            <i className="far fa-heart" />
                          </a>
                          <div className="fetured-btn">
                            <a href="#" className="theme-btn">
                              Quick View
                            </a>
                          </div>
                        </div>
                        <div className="fecure-containt">
                          <h3>Disposable Face Mask</h3>
                          <h4>$45.00</h4>
                          <a href="#" className="theme-btn">
                            Add to Cart
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Products