import logo from "./logo.svg";
import "./App.css";

import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Header from "./components/Layouts/Header";
import Home from "./components/Home/Home";
import Footer from "./components/Layouts/Footer";
import Signup from "./components/Auth/Signup";
import Login from "./components/Auth/Login";
import Profile from "./components/Auth/Profile";
import Medicines from "./components/Medicines/Medicines";
import MedicineDetail from "./components/Medicines/MedicineDetail";
import ContactDoctor from "./components/ContactDoctor/ContactDoctor";

function App() {
  return (
    <div className="home">
      <Toaster />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/medicines" element={<Medicines />} />
        <Route path="/medicine/:id" element={<MedicineDetail />} />
        <Route path="/contact-your-doctor" element={<ContactDoctor />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
