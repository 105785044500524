import React,{useEffect,useState} from 'react';
import Hero from './Hero';
import { useNavigate } from 'react-router';
import { backendUrl } from '../../utils/axios';

const ContactDoctor = () => {

    async function downloadPdf() {
        try {
          const response = await fetch(`${backendUrl}/medicine/downloadPdf`, {
            method: 'GET',
          });
      
          if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'PrescriptionRequestForm.pdf'; // Set the desired filename
            document.body.appendChild(a); // Append anchor to body
            a.click(); // Trigger the download
            a.remove(); // Cleanup
          } else {
            console.error('Error downloading the PDF:', response.statusText);
          }
        } catch (error) {
          console.error('Error downloading the PDF:', error);
        }
      }

    const navigate = useNavigate();
  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-gap-4">
        <Hero />
        <div className="tw-bg-white tw-min-h-screen tw-flex tw-items-center tw-justify-center">
      <div className="tw-max-w-4xl tw-w-full tw-bg-gray-50 tw-p-10 tw-rounded-lg tw-shadow-lg">
        <h2 className="tw-text-3xl tw-font-bold tw-text-green-700 tw-text-center">
          Talk to your doctor about Cost Plus Drug Co.
        </h2>
        <p className="tw-text-center tw-text-green-600 tw-mt-4">
          Check which <span className="tw-text-green-800 tw-underline tw-cursor-pointer" onClick={()=>navigate("/medicines")} >medications we currently carry</span> first, then contact your doctor:
        </p>

        <div className="tw-flex tw-justify-between tw-mt-8">
          {/* Left Section */}
          <div className="tw-flex-1">
            <p className="tw-text-lg tw-font-semibold tw-text-green-700">
              Download & provide this prescriber cover letter to your doctor
            </p>
            <button onClick={downloadPdf} className="tw-mt-4 tw-bg-green-700 tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded-lg tw-shadow-md hover:tw-bg-green-800">
              Download Form
            </button>
          </div>

          {/* Divider */}
          <div className="tw-w-px tw-bg-green-200 tw-h-full tw-mx-8"></div>

          {/* Right Section */}
          <div className="tw-flex-1">
            <p className="tw-text-lg tw-font-semibold tw-text-green-700">
              Ask your doctor to write a new prescription that includes ALL of the following information:
            </p>
            <ul className="tw-mt-4 tw-space-y-2">
              <li className="tw-flex tw-items-center">
                <span className="tw-bg-green-700 tw-text-white tw-rounded-full tw-p-1 tw-mr-2">&#10003;</span>
                <span className="tw-text-green-800">Your name</span>
              </li>
              <li className="tw-flex tw-items-center">
                <span className="tw-bg-green-700 tw-text-white tw-rounded-full tw-p-1 tw-mr-2">&#10003;</span>
                <span className="tw-text-green-800">Your date of birth</span>
              </li>
              <li className="tw-flex tw-items-center">
                <span className="tw-bg-green-700 tw-text-white tw-rounded-full tw-p-1 tw-mr-2">&#10003;</span>
                <span className="tw-text-green-800">Your email address</span>
              </li>
              <li className="tw-flex tw-items-center">
                <span className="tw-bg-green-700 tw-text-white tw-rounded-full tw-p-1 tw-mr-2">&#10003;</span>
                <span className="tw-text-green-800">Your medications</span>
              </li>
              <li className="tw-flex tw-items-center">
                <span className="tw-bg-green-700 tw-text-white tw-rounded-full tw-p-1 tw-mr-2">&#10003;</span>
                <span className="tw-text-green-800">NCPDP ID: #3689568</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContactDoctor;
