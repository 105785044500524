import React, { useState, useEffect } from "react";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  AutocompleteElement,
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { VisibilityOff, Visibility, Image } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { backendUrl, callAxios } from "../../utils/axios";
import axios from "axios";
import { login } from "../../store/slices/user";
const styles = {
  root: {
    "& .MuiInputBase-input:-webkit-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",

      fontWeight: 400,
    },
    "& .MuiInputBase-input:-moz-autofill-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",

      fontWeight: 400,
    },
    "& .MuiInputBase-input::-ms-input-placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",

      fontWeight: 400,
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",

      fontWeight: 400,
    },
    "& .MuiInputBase-input": {
      color: "#858D9D",
      backgroundColor: "#fff",
      fontSize: "16px",
    },
    // backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#858D9D !important",
  },
};
const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

const dispatch = useDispatch();

  const defaultValues = {
    email: "",
    password: "",
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    console.log(values);

    try {
      setLoading(true);
      const { data } = await axios.post(
        `${backendUrl}/auth/login`,
        values
      );
      console.log(data);
      dispatch(login(data));
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      setLoading(false);
      toastMessage("Login Successfully", "success");

      navigate("/");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      {" "}
      <Loading loading={loading} background="#fff" loaderColor="#17b3ab" />
      <div className="contuct-area">
        <div className="container tw-w-full md:tw-w-1/2 lg:tw-w-1/3">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-form-area">
                <h3>Fill your details</h3>
                <FormContainer
                  defaultValues={defaultValues}
                  onSuccess={onSubmit}
                >
                  <div className="tw-w-full tw-flex tw-flex-col tw-gap-2 ">
                    <TextFieldElement
                      name="email"
                      placeholder="Enter your email"
                      required
                      type="email"
                      label="Email"
                      variant="outlined"
                      sx={styles.root}
                      fullWidth
                      size="small"
                    />
                    <TextFieldElement
                      name="password"
                      size="small"
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      required
                      placeholder="Your password"
                      sx={styles.root}
                      validation={{
                        validate: (value) => {
                          console.log(value);
                          let regex = /^(?=.*[0-9])(?=.*[@#$%^&+=!])(.{8,})$/;
                          if (regex.test(value)) {
                            return true;
                          } else {
                            return "Password must contain atleast 8 characters and a special character and a number (0-9) ";
                          }
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff fontSize="small" />
                              ) : (
                                <Visibility fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="tw-full tw-flex tw-justify-center">
                      <button className="signup-button tw-mt-8" type="submit">
                     Login
                      </button>
                    </div>
                  </div>
                </FormContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
