import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toastMessage } from "../../utils/toast";
import { SupervisedUserCircleOutlined } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
const Header = () => {
  const { token, user } = useSelector((state) => state.user);
  return (
    <header className="header1">
      <nav
        id="main-navigation-wrapper"
        className="navbar navbar-default header-middle header-area header-middle position-relative"
      >
        <div className="container">
          <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 navbar-header">
            <div className="header-logo">
              <Link to="/">
                <img src="images/logo.png" alt="" className="logo" />
              </Link>
            </div>
            <button
              type="button"
              data-toggle="collapse"
              data-target="#main-navigation"
              aria-expanded="false"
              className="navbar-toggle collapsed"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          </div>
          <div
            id="main-navigation"
            className="col-xl-9 col-lg-12 col-md-12 collapse navbar-collapse "
          >
            <ul className="nav navbar-nav tw-flex tw-items-center">
              <li className="dropdown ">
                <Link to="/" className="active">
                  Home
                </Link>
              </li>
              <li className="dropdown">
                <a href="about-v.1.html">About us</a>
                {/* <i className="fa fa-chevron-down" /> */}
                {/* <ul className="dropdown-submenu">
                <li>
                  <a href="about-v.1.html">About us-1</a>
                </li>
                <li>
                  <a href="about-v-2.html">About us-2</a>
                </li>
                <li className="submenu_child">
                  <a href="doctors.html">Our Doctor</a>
                  <i className="fa fa-chevron-down" />
                  <ul className="dropdown-submenu second_submenu">
                    <li>
                      <a href="doctors-details.html">Doctor's Detail</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="faq.html">FAQ</a>
                </li>
                <li>
                  <a href="pricing.html">Pricing</a>
                </li>
                <li>
                  <a href="comingsoon.html">Coming Soon</a>
                </li>
                <li>
                  <a href="maintenance.html">Maintenance</a>
                </li>
                <li>
                  <a href="404.html">404 Page</a>
                </li>
              </ul> */}
              </li>
              <li className="dropdown">
                <a href="services-v.1.html">Our Services</a>
                {/* <i className="fa fa-chevron-down" /> */}
                {/* <ul className="dropdown-submenu">
                <li className="submenu_child">
                  <a href="services-v.1.html">Services-v.1</a>
                  <i className="fa fa-chevron-down" />
                  <ul className="dropdown-submenu second_submenu">
                    <li>
                      <a href="services-details.v.1.html">Services-Details.v.1</a>
                    </li>
                  </ul>
                </li>
                <li className="submenu_child">
                  <a href="services-v.2.html">Services-v.2</a>
                  <i className="fa fa-chevron-down" />
                  <ul className="dropdown-submenu second_submenu">
                    <li>
                      <a href="services-details.v.2.html">Services-Details.v.2</a>
                    </li>
                  </ul>
                </li>
                <li className="submenu_child">
                  <a href="services-v.3.html">Services-v.3</a>
                  <i className="fa fa-chevron-down" />
                  <ul className="dropdown-submenu second_submenu">
                    <li>
                      <a href="services-details.v.3.html">Services-Details.v.3</a>
                    </li>
                  </ul>
                </li>
              </ul> */}
              </li>
              {/* <li className="dropdown">
              <a href="portfolio-2col.html">Portfolio</a>
              <i className="fa fa-chevron-down" />
              <ul className="dropdown-submenu">
                <li>
                  <a href="portfolio-2col.html">Portfolio-2col</a>
                </li>
                <li>
                  <a href="portfolio-3col.html">Portfolio-3col</a>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a href="blog-list.html">Blog</a>
              <i className="fa fa-chevron-down" />
              <ul className="dropdown-submenu">
                <li>
                  <a href="blog-grid.html">Blog-Grid</a>
                </li>
                <li>
                  <a href="blog-left.html">Blog-List-Left</a>
                </li>
                <li>
                  <a href="blog-right.html">Blog-List-Right</a>
                </li>
                <li>
                  <a href="blog-details.html">Blog-details</a>
                </li>
              </ul>
            </li> */}
              <li className="dropdown">
                <Link to="/medicines">Medicines</Link>
              </li>
              {/* <li className="dropdown">
                <a href="shop-detail.html">Track Order</a>
              </li> */}
              <li className="dropdown">
                <a href="contact.html">Contact us</a>
              </li>
              <li className="dropdown">
                <div className="tw-flex tw-cursor-pointer ">
                 <PersonIcon className={`lg:tw-text-[#072264] tw-text-white lg:tw-ml-8`} />
                  {token && (
                    <p className="tw-text-sm tw-font-semibold tw-text-[#17b3ab]">
                      Welcome,
                      <br />
                      {user?.firstName + " " + user?.lastName}
                    </p>
                  )}
                </div>
                <i class="fa fa-chevron-down"></i>
                {token ? (
                  <ul className="dropdown-submenu">
                    <li>
                      <Link to="/profile">View Profile</Link>
                    </li>
                    <li>
                      <Link to="/track-order">Track Order</Link>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={() => {
                          window.localStorage.removeItem("token");
                          window.localStorage.removeItem("user");
                          window.location.reload();
                          window.location.href = "/";
                          toastMessage("Logout Successfully", "success");
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                ) : (
                  <ul className="dropdown-submenu">
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                    <li>
                      <Link to="/signup">Signup</Link>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
